<template>
  <div class="d-flex flex-column floating__button bg-white" style="padding: 0.5rem;">
    <!-- Discount -->
    <AddDiscount v-if="is_discount" />

    <div class="d-flex" :class="!checkPermission('buat transaksi') ? '' : 'align-items-center'" style="margin-top: 0.5rem;gap: 1rem;">
      <!-- Save Cart -->
      <b-card v-b-modal.modal-save-transaction no-body class="mb-0 bg-pink-2 py-2 px-3 rounded h-100 w-25"
        variant="primary" id="button--show__save--transaction">
        <div class="d-flex align-items-center justify-content-center">
          <img src="/save.svg" width="14" height="14" />
        </div>
      </b-card>

      <!-- Reset Cart -->
      <b-card no-body class="mb-0 bg-red py-2 px-3 rounded h-100 w-25" variant="primary"
        id="button--show__save--transaction" @click="deleteCart">
        <div class="d-flex align-items-center justify-content-center">
          <span class="text-white mr-1">Reset</span>
          <font-awesome-icon icon="repeat" size="md" class="text-white" />
        </div>
      </b-card>

      <!-- Create Transaction -->
      <div class="d-flex flex-column w-100">
        <b-button variant="primary" class="mb-0 bg-primary h-100 p-1 d-flex justify-content-between rounded w-100"
          style="cursor: pointer;" @click="directPage" id="button--direct__page" :disabled="!checkPermission('buat transaksi')">
          <div class="d-flex align-items-center justify-content-between w-100">
            <h6 id="tour-process-transaction" class="text-white mb-0 size14">
              <label class="size12 fw-bold-500 text-white" v-if="discountTotal > 0">Hemat {{ discountTotal |
        formatAmount }} 🎉</label>
              <br v-if="discountTotal > 0" />
              <span class="size20">{{ totalPrice || 0 | formatAmount }}</span>
              <span v-if="discountTotal > 0" class="size12 fw-bold-500" style="text-decoration: line-through">{{
        subtotalCart | formatAmount }}</span>
            </h6>
            <div class="rounded__frame print__logo mr-0" style="opacity: 0">
              <b-img width="17" :src="require('/src/assets/images/icons/printer.svg')" />
            </div>
            <feather-icon class="text-white font-weight-bolder" icon="ArrowRightIcon" size="20" />
          </div>
        </b-button>
        <small class="text-danger mt-25" v-if="!checkPermission('buat transaksi')">Anda tidak memiliki akses untuk membuat transaksi.</small>
      </div>
    </div>

    <ModalSaveTransaction />
    <ModalAdditionalCost :isChange="isChange" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { BRow, BCol, BCard, BImg, VBModal, BSpinner, BButton } from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";

import ModalSaveTransaction from "@/components/Pos2/Modal/SaveTransaction.vue";
import AddDiscount from "@/components/Pos2/AddDiscount.vue";
import ModalAdditionalCost from "@/components/Pos2/Modal/AdditionalCost.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    ModalSaveTransaction,
    AddDiscount,
    ModalAdditionalCost,
    BSpinner,
    BButton
  },
  directives: {
    "b-modal": VBModal,
  },
  props: ["is_discount", "is_process", "deleteCart"],
  computed: {
    ...mapState({
      formAdditionalCost: (state) => state.cart.formAdditionalCost,
      totalAdditionalCost: (state) => state.cart.totalAdditionalCost,
    }),
  },
  data() {
    return {
      isLoading: false,
      discount: this.$store.state.cart.discountCart,
      discountType: this.$store.state.cart.discountType,
      totalPrice: this.$store.state.cart.totalPrice,
      discountTotal: this.$store.state.cart.discountTotal,
      subtotalCart: this.$store.state.cart.subtotalCart,
      isChange: 0,
    };
  },
  watch: {
    /* eslint-disable func-names */
    "$store.state.cart.discountCart": function (value) {
      this.discount = value;
    },
    "$store.state.cart.totalPrice": function (value) {
      this.totalPrice = value;
    },
    "$store.state.cart.discountTotal": function (value) {
      this.discountTotal = value;
    },
    "$store.state.cart.subtotalCart": function (value) {
      this.subtotalCart = value;
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    };
  },
  mounted() { },
  methods: {
    ...mapActions("cart", ["removeAddtionalCost"]),
    async resetDiscount() {
      this.isLoading = true;
      const response = await this.removeAddtionalCost({
        uuid: this.$store.state.cart.cartUuid,
        payload: "",
      });

      if (response.status == 200) {
        successNotification(
          this,
          "Success",
          "Biaya tambahan berhasil dihapus."
        );
        this.isLoading = false;
        const item = response.data.data;
        this.$store.commit(
          "cart/SET_TOTAL_ADDITIONAL_COST",
          item.additional_fee_total
        );
        this.$store.commit("cart/setDiscountCart", item.discount_value);
        this.$store.commit("cart/setDiscountType", item.discount_type);
        this.$store.commit("cart/setDiscountTotal", item.discount_total);
        this.$store.commit("cart/setSubtotalCart", item.subtotal);
        this.$store.commit("cart/setTotalPrice", item.total);
        this.$store.commit("cart/setTotalBuy", item.items.length);
        this.$store.commit("cart/setCartUuid", item.uuid);
        this.$store.commit("cart/setResultCartActive", item);
        this.$store.commit("cashier/setProduct", item.items.length);
        localStorage.removeItem("additional_cost");
        localStorage.setItem("cartUuid", item.uuid);
        localStorage.setItem("cash_total_payment", item.total);
        localStorage.setItem("customer", JSON.stringify(item.customer));
      }
      this.isLoading = false;
    },
    directPage() {
      if (!this.$store.state.customer.customer.name) {
        localStorage.removeItem("customer_data");
      }
      localStorage.removeItem("payloadTransaksi");
      localStorage.removeItem("poSO");
      localStorage.removeItem("delivery_pickup");
      localStorage.removeItem("invoice_status");
      localStorage.setItem("is_full_payment", true);

      const transaction_query = {
        bonus: this.$store.state.cart.discountTotal || 0,
        total: this.totalPrice || 0,
        uuid: this.$route.query.uuid,
        step: 1,
      }
      localStorage.setItem('transaction_query', JSON.stringify(transaction_query))
      // this.$router.push({
      //   name: "payment.index",
      //   query: {
      //     bonus: this.$store.state.cart.discountTotal || 0,
      //     total: this.totalPrice || 0,
      //     uuid: this.$route.query.uuid,
      //   },
      // });
      this.$bvModal.show('modal-payment-pos')
      this.$router.replace({ query: { s: 1, uuid: this.$route.query.uuid || null } });
    },
  },
};
</script>

<style lang="scss">
.btn-discount {
  background: #ffe4e6;
  border-radius: 0 16px 0 0;
}

.rounded__frame {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #d5f1ee;
  margin-right: 1rem;

  &.print__logo {
    background: #e4f8f6;
  }
}

.button--save {
  width: 50px;
  height: 50px;

  @media (max-height: 799px) {
    width: 30px;
    height: 30px;
  }

  .save--icon {
    width: 30px;

    @media (max-height: 799px) {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
